<template>
    <app-transition>
        <Dialog
            class="ChangePrice"
            :only-text="true"
            @close="handleClose"
        >
            <v-row text-center="">
                <v-col>
                    <div class="ChangePrice-title">
                        {{ $t('dialogs.to_turn_off_price') }} <router-link :to="{name: 'user.setting.creator.messages'}">{{ $t('dialogs.messages_page') }}</router-link>
                    </div>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "ChangePriceDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.ChangePrice {
    &-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 19px;
        width: 100%;
        a {
            text-decoration: underline;
        }
    }
}
</style>
