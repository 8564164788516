<template>
    <app-transition>
        <Dialog
            class="NoodzlersCantTurnOffMessages"
            :only-text="true"
            @close="handleClose"
        >
            <v-row text-center="">
                <v-col>
                  {{ $t('dialogs.noodzlers_cant_turn_off_messages') }}
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "NoodzlersCantTurnOffMessagesDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
    components: {
        Dialog,
    }
}
</script>
